import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    notifications: [],
}

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        queue: (state, action) => {
            const notification = action.payload
            notification.options = {
                key: `${new Date().getTime()}${Math.random()}`,
                ...notification.options,
            }
            state.notifications.push(notification)
        },
        remove: (state, action) => {
            state.notifications = state.notifications.filter(
                notification => notification.options.key !== action.payload
            )
        },
    },
})

export const { queue, remove } = notificationsSlice.actions

export default notificationsSlice.reducer
